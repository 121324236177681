import React from 'react'
import { graphql } from 'gatsby'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import classnames from 'classnames'
import MobileDetect from 'mobile-detect'

import SEO from 'components/seo'
import styles from './sitemap.module.css'

const Column = ({ children, title }) => (
  <div className={styles.column}>
    <h3 className={styles.columnTitle}>{ title }</h3>
    <span className={styles.columnAccent}></span>
    <div className={styles.columnContent}>
      { children }
    </div>
  </div>
)

const LinkGroup = ({ children }) => <ul>{ children }</ul>
const LinkItem = ({ children, to }) => (
  <li>
    <AniLink 
      fade 
      to={to} 
      className={classnames(styles.link, 'underline-animation bluegray')}
    >
      { children }
    </AniLink>
  </li>
)

const Sitemap = ({ data: { allWordpressPage, allWordpressPost, wordpressPage, wordpressAcfOptions }}) => {
  const userAgent = typeof window !== 'undefined' ?  window.navigator.userAgent.toString() : ''
  const currentPage = wordpressPage
  const md = new MobileDetect(userAgent)

  const createTree = (items, parent = 0) => {
    let sorted = []

    items.map(item => {
      if (item.wordpress_parent === parent) {
        let children = createTree(items, item.wordpress_id)

        if (children) {
          item.children = children
        }

        sorted.push(item)
        return null
      }

      return item
    })

    return sorted
  }

  const sortedPages = createTree(allWordpressPage.nodes)
  const rootPages = sortedPages.map(page => {
    if (page.slug === 'sitemap') return null
    if (page.slug === 'dummy-page-do-not-delete') return null

    if (page.status === 'publish') {
      let additional = []

      if (page.slug === 'what-we-do') {
        additional = page.children.map(child => {
          if (child.status === 'publish') {
            return (
              <LinkItem key={child.id} to={child.path}>
                <span dangerouslySetInnerHTML={{ __html: child.title }}></span>
              </LinkItem>
            )
          }

          return null
        })
      }

      return [<LinkItem key={page.id} to={page.path}><span dangerouslySetInnerHTML={{ __html: page.title }}></span></LinkItem>, ...additional]
    }

    return null
  })

  const servicesPages = sortedPages.filter(page => page.slug === 'what-we-do')
  let children = servicesPages[0].children
  children = children.sort((a, b) => (a.menu_order > b.menu_order) ? 1 : -1)

  const services = children.map(child => {
    let subpages = null

    if (child.children) {
      const sortedSubpages = child.children.sort((a, b) => (a.menu_order > b.menu_order) ? 1 : -1)
      subpages = sortedSubpages.map(subpage => <LinkItem key={subpage.id} to={subpage.path}><span dangerouslySetInnerHTML={{ __html: subpage.title }}></span></LinkItem>)
    }

    return (
      <div className={styles.serviceModule} key={child.id}>
        <h4 dangerouslySetInnerHTML={{ __html: child.title }}></h4>

        <LinkGroup>
          { subpages }
        </LinkGroup>
      </div>
    )
  })

  const careerPostings = allWordpressPost.edges.map(jobPost => {
    const { status, slug, id, title } = jobPost.node

    if (status === 'publish') {
      return <LinkItem key={id} to={`/careers/${slug}`}><span dangerouslySetInnerHTML={{ __html: title }}></span></LinkItem>
    }

    return null
  })

  return (
    <>
      <SEO title={wordpressPage.title} yoast={currentPage.yoast_meta && currentPage.yoast_meta} />

      <div className={styles.container}>
        <h1 className={styles.title}>Array Dental Sitemap</h1>

        <div className={classnames(styles.layout, md.tablet() && styles.isTablet)}>
          <Column title={'Site Links'}>
            <LinkGroup>
              { rootPages }
            </LinkGroup>
          </Column>

          <Column title={'What We Do'}>
            { services }
          </Column>

          <Column title={'Career Postings'}>
            <LinkGroup>
              { careerPostings }
            </LinkGroup>
          </Column>

          <Column title={'About Us'}>
            <p dangerouslySetInnerHTML={{ __html: wordpressAcfOptions.options.seo_locality_text }}></p>
          </Column>
        </div>
      </div>
    </>
  )
}

export default Sitemap

export const pageQuery = graphql`
  query {
    wordpressPage(slug: {eq: "sitemap"}) {
      title
      slug
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_twitter_description
        yoast_wpseo_twitter_image
        yoast_wpseo_twitter_title
        yoast_wpseo_website_name
        yoast_wpseo_person_name
        yoast_wpseo_metadesc
        yoast_wpseo_facebook_type
        yoast_wpseo_facebook_title
        yoast_wpseo_facebook_image
        yoast_wpseo_facebook_description
        yoast_wpseo_company_or_person
        yoast_wpseo_company_name
        yoast_wpseo_company_logo
        yoast_wpseo_social_defaults {
          facebook_site
          instagram_url
          linkedin_url
          myspace_url
          og_default_image
          og_default_image_id
          og_frontpage_desc
          og_frontpage_image
          og_frontpage_image_id
          og_frontpage_title
          opengraph
          pinterest_url
          pinterestverify
          twitter
          twitter_card_type
          twitter_site
          wikipedia_url
          youtube_url
        }
      }
    }

    allWordpressPage {
      nodes {
        id
        menu_order
        slug
        path
        title
        status
        wordpress_id
        wordpress_parent
      }
    }

    allWordpressPost {
      edges {
        node {
          id
          slug
          status
          template
          format
          title
          wordpress_id
        }
      }
    }

    wordpressAcfOptions {
      options {
        seo_locality_text
      }
    }
  }
`